











import Component, { mixins } from "vue-class-component";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import { ResponsiveMixin } from "@/mixins/Responsive";
import { Prop } from "vue-property-decorator";

@Component({
  name: "no-data",
  components: {
    LottieAnimation
  }
})
export default class NoData extends mixins(ResponsiveMixin) {
  @Prop({ required: false, default: null })
  width!: number | null;

  get itemWidth(): number {
    if (this.width) {
      return this.width;
    }
    if (this.isDesktop) {
      return 512;
    } else {
      return window.innerWidth - 56;
    }
  }
}
