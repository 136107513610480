

































import Component, { mixins } from "vue-class-component";
import { ProgramPlayerRequests } from "@sportango/backend";
import { AutoCompleteMixin, TextFieldMixin } from "./mixins";
import { Prop, VModel } from "vue-property-decorator";
import { ResponsiveMixin } from "@/mixins/Responsive";
import { ValidationRules } from "@/utils/validation";

@Component({
  name: "days-input"
})
export default class DaysInput extends mixins(
  TextFieldMixin,
  AutoCompleteMixin,
  ResponsiveMixin
) {
  @VModel({ type: [String, Array] })
  field!: string | string[];

  @Prop({ required: false, default: null, type: Function })
  checkIfDayAllowed!: ((day: string) => boolean) | null;

  get finalRules(): ValidationRules {
    if (this.rules) {
      return [...this.rules, this.validateLength];
    }
    return [this.validateLength];
  }

  private validateLength(): true | string {
    if (!this.multiple) {
      if (this.minLength !== 0) {
        if (!this.field) {
          return this.minLengthMessage;
        }
      }
    } else {
      if (this.minLength !== 0) {
        if (!this.field) {
          return this.minLengthMessage;
        } else if (this.field.length < this.minLength) {
          return this.minLengthMessage;
        }
      }
      if (this.maxLength !== 0) {
        if (this.field) {
          if (this.field.length > this.maxLength) {
            return this.maxLengthMessage;
          }
        }
      }
    }
    return true;
  }

  dayAllowed(day: string): boolean {
    if (this.checkIfDayAllowed) {
      return this.checkIfDayAllowed(day);
    }
    return true;
  }

  get daysOptions(): Array<Record<"text" | "value", string>> {
    return ProgramPlayerRequests.options.days.map((d) => {
      return this.isDesktop
        ? {
            text: d,
            value: d
          }
        : {
            text: d[0],
            value: d
          };
    });
  }
}
