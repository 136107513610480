








































import Component, { mixins } from "vue-class-component";
import { Prop, VModel, Watch } from "vue-property-decorator";
import { TextFieldMixin } from "./mixins";
import TimeIcon from "@/assets/icons/saxcons/clock-linear.svg";

@Component({
  name: "dialog-time-picker",
  components: {
    TimeIcon
  }
})
export default class DialogTimePicker extends mixins(TextFieldMixin) {
  private pickerActive: boolean;
  @VModel({ type: String })
  field!: string | undefined;
  @Prop({ required: true })
  name!: string;
  constructor() {
    super();
    this.pickerActive = false;
  }

  get textValue(): string | undefined {
    if (!this.field) {
      if (this.placeholder && this.placeholder.length > 0) {
        return this.placeholder;
      }
      return undefined;
    }
    let time = this.field.split(":");
    const hour = Number(time[0]);
    const minute = time[1];
    if (hour > 12) {
      return `${hour - 12}:${minute} PM`;
    } else if (hour === 12) {
      return `12:${minute} PM`;
    } else {
      return this.field + " AM";
    }
  }

  @Watch("pickerActive")
  watchIfActive(): void {
    if (this.pickerActive) {
      if (Object.keys(this.$route.query).length > 0) {
        this.$router.push(
          `${this.$route.fullPath}&timePickerOpen=${this.name}`
        );
      } else {
        this.$router.push(`${this.$route.path}?timePickerOpen=${this.name}`);
      }
    } else {
      this.$router.back();
    }
  }

  mounted(): void {
    if (this.$route.query.timePickerOpen === this.name) {
      this.$router.replace(
        this.$route.fullPath.replace(`?timePickerOpen=${this.name}`, "")
      );
    }
  }
}
